<template>
  <yandex-map
    :settings="settings"
    :options="options"
    :coords="center"
    :zoom="zoom"
    :bounds="bounds"
    :controls="[]"
    :behaviors="behaviors[mapState] || behaviors.default"
    :map-type="satellite ? 'satellite' : 'map'"
    :cluster-options="clusterOptions"
    @map-was-initialized="init"
    @boundschange="centerChange"
    @click="setMarker"
  >
    <ymap-marker
      v-if="!markerUpdateFix && mapState === 'marker:waiting'"
      :coords="targetMarker"
      :icon="targetIcon"
      marker-id="target"
    />
    <template v-else-if="!markerUpdateFix && ['map:default', 'marker:read'].includes(mapState)">
      <ymap-marker
        v-for="(m, i) in markers"
        :key="i"
        :marker-id="m.id"
        :coords="m.coords"
        :icon="m.icon"
        cluster-name="problems"
        @mouseenter="$emit('mouseenter', m)"
        @mouseleave="$emit('mouseleave', m)"
        @click="$emit('markerClick', m)"
      />
    </template>
  </yandex-map>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "YandexMapComponent",
  props: {
    mapState: String,
    satellite: Boolean,
    statusSelected: Array,
    categorySelected: Array,
    targetMarker: Array,
    bounds: {
      type: Array,
      default: () => [
        [0, 0],
        [0, 0],
      ],
    },
    center: {
      type: Array,
      default: () => [0, 0],
    },
    zoom: {
      type: Number,
      default: () => 10,
    },
  },
  data() {
    return {
      zoomDebounce: null,
      markerUpdateFix: false,
      map: null,
      clusterOptions: {
        problems: {
          clusterGroupByCoordinates: true,
          clusterOpenBalloonOnClick: false,
          clusterIconLayout: window.ymaps.templateLayoutFactory.createClass(
            '<div class="sc__cluster-icon">{{ properties.geoObjects.length }}</div>'
          ),
          clusterIconShape: {
            type: "Circle",
            coordinates: [18, 18],
            radius: 18,
          },
        },
      },
      options: {
        restrictMapArea: [
          [43.049064, 47.318494],
          [42.892752, 47.654264],
        ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true,
      },
      settings: {
        apiKey: "2accbdff-2038-4811-9f12-f3799d64a50c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      behaviors: {
        default: ["default"],
        "marker:waiting": [],
      },
      targetIcon: {
        layout: "default#image",
        imageHref: "/public/svg/map-target.svg",
        imageSize: [32, 32],
        imageOffset: [-16, -16],
      },
    };
  },
  computed: {
    markers() {
      return this.$store.getters.socialControlMarkers(this.statusSelected, this.categorySelected);
    },
  },
  mounted() {},
  watch: {
    markers() {
      this.markerUpdateFix = true;
      this.$nextTick(() => {
        this.markerUpdateFix = false;
      });
    },
    mapState() {
      this.markerUpdateFix = true;
      this.$nextTick(() => {
        this.markerUpdateFix = false;
      });
    },
    statusSelected() {
      this.markerUpdateFix = true;
      this.$nextTick(() => {
        this.markerUpdateFix = false;
      });
    },
    categorySelected() {
      this.markerUpdateFix = true;
      this.$nextTick(() => {
        this.markerUpdateFix = false;
      });
    },
  },
  methods: {
    centerChange(e) {
      this.$emit("centerChange", e.get("newCenter"));
    },
    setMarker(e) {
      if (this.mapState === "map:reporting") {
        let coords = e.get("coords");
        // let position = this.getPosition(coords, this.zooms["marker:waiting"]);
        this.$emit("click", coords);
      }
    },
    init(map) {
      this.map = map;
      this.map.cursors.push("arrow");
      this.map.events.add("wheel", () => {
        clearTimeout(this.zoomDebounce);
        this.zoomDebounce = setTimeout(() => {
          this.$emit("centerChange", this.map.getCenter());
          this.$emit("zoom", this.map.action.getCurrentState().zoom);
        }, 200);
      });
    },
  },
  components: {
    yandexMap,
    ymapMarker,
  },
};
</script>
